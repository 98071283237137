<template>
  <div class="home">
    <Loader v-if="loading" :isLoading="isLoading" />
    <div class="animation-home">
      <Header />
      <!-- FIRST SECTION HOME -->
      <div class="container-fluid mt-5 pb-5 space-container">
        <div class="row">
          <div class="col-12 col-md-4">
            <p class="press">press is more</p>
          </div>
          <div class="col-12 col-md-8">
            <h4 class="first-text">
              {{ copies.intro }}
            </h4>
          </div>
        </div>
      </div>
      <div class="b-bottom"></div>
      <!-- /FIRST SECTION HOME -->

      <!-- SECOND SECTION HOME -->
      <div class="container-fluid space-container">
        <div class="row collaboration">
          <div
            :class="isMobile ? 'text-start' : 'text-end'"
            class="col-12 col-lg-4 order-1 order-lg-0"
          >
            <h6 class="change-color">collaborazioni</h6>
            <p>
              {{ copies.collaborations }}
            </p>
          </div>
          <div
            :class="isMobile ? 'flex-column align-items-center' : 'flex-row'"
            class="col-12 col-lg-8 order-0 order-lg-1 d-flex justify-content-end"
          >
            <hooper v-if="artistsArray.length > 0" :settings="hooperSettings">
              <slide
                class=""
                v-for="(artist, index) in artistsArray"
                :key="index"
              >
                <a
                  style="text-decoration: none"
                  :href="'/artista/' + artist.slug"
                  class="single-collaboration"
                >
                  <img
                    :src="
                      'https://pressismore.it/contents/' + artist.cover_image
                    "
                    alt="artist cover"
                  />
                  <div class="info-collaboration info-project">
                    <h4>
                      {{ artist.name }}
                      <svg
                        class="arrow"
                        viewBox="0 0 30 35"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M28.9959 0.9095C28.9458 0.35949 28.4594 -0.0458109 27.9094 0.0042359L18.9464 0.819824C18.3964 0.869874 17.9911 1.35632 18.0411 1.90633C18.0912 2.45635 18.5776 2.86165 19.1277 2.8116L27.0947 2.08663L27.8197 10.0537C27.8698 10.6037 28.3562 11.009 28.9062 10.959C29.4562 10.9089 29.8615 10.4225 29.8115 9.87247L28.9959 0.9095ZM1.76828 34.0459L28.7683 1.64024L27.2317 0.360003L0.231724 32.7656L1.76828 34.0459Z"
                          fill="currentColor"
                        />
                      </svg>
                    </h4>
                  </div>
                </a>
              </slide>
              <hooper-navigation slot="hooper-addons"></hooper-navigation>
            </hooper>

            <!-- <div v-if="!isMobile" class="single-collaboration">
              <img src="../assets/Rectangle 2.png" alt="" />
              <div class="info-collaboration info-project">
                <h4>
                  saint huck
                  <svg
                    class="arrow"
                    viewBox="0 0 30 35"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M28.9959 0.9095C28.9458 0.35949 28.4594 -0.0458109 27.9094 0.0042359L18.9464 0.819824C18.3964 0.869874 17.9911 1.35632 18.0411 1.90633C18.0912 2.45635 18.5776 2.86165 19.1277 2.8116L27.0947 2.08663L27.8197 10.0537C27.8698 10.6037 28.3562 11.009 28.9062 10.959C29.4562 10.9089 29.8615 10.4225 29.8115 9.87247L28.9959 0.9095ZM1.76828 34.0459L28.7683 1.64024L27.2317 0.360003L0.231724 32.7656L1.76828 34.0459Z"
                      fill="currentColor"
                    />
                  </svg>
                </h4>
              </div>
            </div> -->
          </div>
        </div>
      </div>
      <div class="b-bottom"></div>
      <!-- /SECOND SECTION HOME -->

      <!-- THIRD SECTION HOME -->
      <div class="container-fluid">
        <div class="row section-banner">
          <div class="col-12">
            <MarqueeText
              class="title-project"
              :repeat="6"
              :duration="6"
              style="z-index: 1"
            >
              <span class="change-color">Top news del mese &nbsp;</span>
            </MarqueeText>
          </div>
        </div>
      </div>
      <div class="container-fluid space-container">
        <div class="row project">
          <div
            v-for="(item, index) in newsArray"
            :key="index"
            class="col-12 col-lg-4"
          >
            <a :href="'/news?id=' + item.id" class="single-project">
              <img
                :src="'https://pressismore.it/contents/' + item.cover_image"
                alt="news cover"
              />
              <div class="info-project">
                <h4>
                  {{ item.title_preview }}
                  <svg
                    class="arrow"
                    viewBox="0 0 30 35"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M28.9959 0.9095C28.9458 0.35949 28.4594 -0.0458109 27.9094 0.0042359L18.9464 0.819824C18.3964 0.869874 17.9911 1.35632 18.0411 1.90633C18.0912 2.45635 18.5776 2.86165 19.1277 2.8116L27.0947 2.08663L27.8197 10.0537C27.8698 10.6037 28.3562 11.009 28.9062 10.959C29.4562 10.9089 29.8615 10.4225 29.8115 9.87247L28.9959 0.9095ZM1.76828 34.0459L28.7683 1.64024L27.2317 0.360003L0.231724 32.7656L1.76828 34.0459Z"
                      fill="currentColor"
                    />
                  </svg>
                </h4>
              </div>
            </a>
          </div>
        </div>
      </div>
      <div class="b-bottom"></div>
      <!-- /THIRD SECTION HOME -->

      <div class="container-fluid mt-5 pb-5 space-container">
        <div class="row pt-4 mb-5 pb-5">
          <div :class="isMobile ? 'mb-5' : 'mb-0'" class="col-12 col-lg-4">
            <p class="press">la mission</p>
          </div>
          <div class="col-12 col-lg-8">
            <h4 class="first-text">
              {{ copies.mission }}
            </h4>
          </div>
        </div>
      </div>
      <div class="container-fluid mt-5 pb-5 space-container">
        <div :class="isMobile ? 'pt-2' : 'pt-4'" class="row">
          <div class="col-12">
            <p class="press">servizi</p>
          </div>
        </div>
      </div>
      <div class="b-bottom"></div>
      <div class="ufficio-stampa list space-container">
        <div class="macro-area">
          <p>Ufficio stampa & pr</p>
        </div>
        <div class="micro-aree flex-column">
          <div
            :class="isMobile ? 'flex-column' : null"
            class="d-flex justify-content-between mb-4"
          >
            <p>Comunicati stampa</p>
            <p>Press Kit</p>
            <p>Anteprime video</p>
          </div>
          <div
            :class="isMobile ? 'flex-column' : null"
            class="d-flex justify-content-between"
          >
            <p>Interviste e recensioni</p>
            <p>Media relations</p>
            <p>Rassegne stampa</p>
          </div>
        </div>
      </div>
      <div
        style="border-bottom: none"
        class="social-media list space-container"
      >
        <div class="macro-area">
          <p>Social media</p>
        </div>
        <div class="micro-aree">
          <p>Content strategy</p>
          <p>Marketing / Social campaigns</p>
          <p>Social Media Management</p>
        </div>
      </div>

      <div class="b-bottom"></div>

      <Footer />
    </div>
  </div>
</template>

<script>
import Footer from "@/components/Footer.vue";
import Header from "@/components/Header.vue";
import Loader from "@/components/Loader.vue";
import axios from "axios";
import { Hooper, Navigation as HooperNavigation, Slide } from "hooper";
import MarqueeText from "vue-marquee-text-component";

export default {
  name: "Home",
  data() {
    return {
      api: process.env.VUE_APP_API_ENDPOINT,
      loading: true,
      isMobile: false,
      artistsArray: [],
      newsArray: [],
      copies: {
        intro: "",
        collaborations: "",
        mission: "",
      },
      hooperSettings: {
        itemsToSlide: 1,
        autoPlay: true,
        playSpeed: 3000,
        transition: 700,
        breakpoints: {
          200: {
            itemsToShow: 1,
            centerMode: false,
          },
          700: {
            itemsToShow: 2,
            centerMode: false,
          },
          1000: {
            itemsToShow: 2,
            centerMode: false,
          },
        },
      },
    };
  },
  props: ["isLoading"],
  components: {
    Loader,
    Header,
    Footer,
    Hooper,
    Slide,
    HooperNavigation,
    MarqueeText,
  },

  async mounted() {
    const app = this;
    await app.fetchCopies();
    await app.fetchArtist();
    await app.fetchNews();
    setTimeout(function () {
      app.loading = false;
    }, 1300);
    if (window.innerWidth < 767) {
      app.isMobile = true;
    }
    window.addEventListener("resize", function () {
      if (window.innerWidth > 767) {
        app.isMobile = false;
      } else {
        app.isMobile = true;
      }
    });
  },
  methods: {
    async fetchCopies() {
      const app = this;
      try {
        const copies = await axios.get(app.api + "/copiesbypage?page=home");

        app.copies.intro =
          copies.data.find((copy) => copy.name === "intro").content ?? "";
        app.copies.collaborations =
          copies.data.find((copy) => copy.name === "collaborations").content ??
          "";
        app.copies.mission =
          copies.data.find((copy) => copy.name === "mission").content ?? "";
      } catch (err) {
        console.log("Errore:", err);
      }
    },
    async fetchArtist() {
      const app = this;
      try {
        const artists = await axios.get(app.api + "/artists");
        const highlightedArtists = artists.data.filter(
          (artist) => artist.highlights === "si"
        );

        app.artistsArray = highlightedArtists;
        console.log("Due artisti casuali con highlight:", highlightedArtists);
      } catch (err) {
        console.log("Errore:", err);
      }
    },

    async fetchNews() {
      const app = this;
      try {
        const news = await axios.get(app.api + "/newsbytime");
        console.log("news", news);
        const highlightedNews = news.data;
        if (highlightedNews.length < 1) {
          console.err("Non ci sono abbastanza news.");
          return;
        }

        app.newsArray = highlightedNews.slice(0, 3);
        console.log(
          "Tre news casuali con highlight:",
          highlightedNews.slice(0, 3)
        );
      } catch (err) {
        console.log("Errore:", err);
      }
    },
  },
};
</script>
<style lang="scss"></style>
