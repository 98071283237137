var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"container-fluid space-container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('p',{staticClass:"other mb-5",on:{"click":_vm.clickBack}},[_vm._v("BACK")])]),_c('div',{staticClass:"col-12"},[_c('h4',[_vm._v(" "+_vm._s(_vm.selected.title_news)+" ")])]),_c('div',{staticClass:"col-12"},[_c('img',{attrs:{"src":'https://pressismore.it/contents/' + _vm.selected.cover_image,"alt":""}})]),_c('div',{staticClass:"col-12 mb-5 pb-5"},[(!_vm.showMore)?_c('p',{staticClass:"info-description",domProps:{"innerHTML":_vm._s(_vm.selected.description.slice(0, 800))}}):_c('p',{staticClass:"info-description",domProps:{"innerHTML":_vm._s(_vm.selected.description)}}),(_vm.selected.description.length > 800)?_c('p',{staticClass:"more-description",on:{"click":_vm.toggleReadMore}},[_vm._v(" "+_vm._s(_vm.showMore ? "Leggi di meno" : "Leggi di più")+" ")]):_vm._e()])]),(
        _vm.selected.testo ||
        _vm.selected.musiche ||
        _vm.selected.voci ||
        _vm.selected.cori ||
        _vm.selected.chitarre ||
        _vm.selected.basso ||
        _vm.selected.batteria ||
        _vm.selected.registrato ||
        _vm.selected.masterizzato ||
        _vm.selected.prodotto
      )?_c('div',{staticClass:"b-bottom",staticStyle:{"position":"absolute","left":"0px"}}):_vm._e(),(
        _vm.selected.testo ||
        _vm.selected.musiche ||
        _vm.selected.voci ||
        _vm.selected.cori ||
        _vm.selected.chitarre ||
        _vm.selected.basso ||
        _vm.selected.batteria ||
        _vm.selected.registrato ||
        _vm.selected.masterizzato ||
        _vm.selected.prodotto
      )?_c('div',{staticClass:"row credits"},[_vm._m(0),_c('div',{staticClass:"col-12 col-lg-5",class:_vm.isMobile ? 'mt-5' : null},[_c('ul',[(_vm.selected.testo)?_c('li',[_vm._v("Testo: "+_vm._s(_vm.selected.testo))]):_vm._e(),(_vm.selected.musiche)?_c('li',[_vm._v(" Musiche e arrangiamenti: "+_vm._s(_vm.selected.musiche)+" ")]):_vm._e(),(_vm.selected.voci)?_c('li',[_vm._v("Voci: "+_vm._s(_vm.selected.voci))]):_vm._e(),(_vm.selected.cori)?_c('li',[_vm._v("Cori: "+_vm._s(_vm.selected.cori))]):_vm._e(),(_vm.selected.chitarre)?_c('li',[_vm._v("Chitarre: "+_vm._s(_vm.selected.chitarre))]):_vm._e(),(_vm.selected.basso)?_c('li',[_vm._v("Basso: "+_vm._s(_vm.selected.basso))]):_vm._e(),(_vm.selected.batteria)?_c('li',[_vm._v("Batteria: "+_vm._s(_vm.selected.batteria))]):_vm._e(),(_vm.selected.registrato)?_c('li',[_vm._v(" Registrato e mixado da: "+_vm._s(_vm.selected.registrato)+" ")]):_vm._e(),(_vm.selected.masterizzato)?_c('li',[_vm._v(" Masterizzato da: "+_vm._s(_vm.selected.masterizzato)+" ")]):_vm._e(),(_vm.selected.prodotto)?_c('li',[_vm._v(" Prodotto da: "+_vm._s(_vm.selected.prodotto)+" ")]):_vm._e()])])]):_vm._e()]),(_vm.allLives.length > 0)?_c('div',{staticClass:"container-fluid mt-5 pb-5 space-container"},[_vm._m(1)]):_vm._e(),_c('div',{staticClass:"b-bottom"}),(this.fetchStatusLives === 'pending')?_c('div',{staticClass:"row container-card justify-content-center mt-5 mb-5 pt-5"},[_vm._m(2)]):_vm._e(),(this.fetchStatusLives === 'success')?_c('div',_vm._l((_vm.allLives),function(live,index){return _c('div',{key:index,staticClass:"list space-container"},[_c('div',{staticClass:"macro-area"},[_c('p',[_vm._v(_vm._s(live.citta))])]),_c('div',{staticClass:"micro-aree"},[_c('p',{domProps:{"innerHTML":_vm._s(live.location)}}),(!_vm.isMobile)?_c('p',{domProps:{"innerHTML":_vm._s(live.feat)}}):_vm._e(),_c('p',{domProps:{"innerHTML":_vm._s(live.date)}})])])}),0):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 col-lg-7"},[_c('p',{staticClass:"press"},[_vm._v("Credits")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row pt-4"},[_c('div',{staticClass:"col-12"},[_c('p',{staticClass:"press"},[_vm._v("BRX!T LIVE")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"spinner-border custom-spinner",attrs:{"role":"status"}},[_c('span',{staticClass:"sr-only"},[_vm._v("Loading...")])])
}]

export { render, staticRenderFns }