<template>
  <div class="rassegna">
    <div class="container-fluid space-container">
      <ul class="nav nav-pills nav-fill">
        <li class="nav-item p-1">
          <button
            @click="changeActiveTab('article')"
            :class="{
              'nav-link': true,
              active: activeTab == 'article',
            }"
            aria-current="page"
          >
            Articoli
          </button>
        </li>
        <li class="nav-item p-1">
          <button
            @click="changeActiveTab('audio')"
            :class="{
              'nav-link': true,
              active: activeTab == 'audio',
            }"
          >
            Audio
          </button>
        </li>
        <li class="nav-item p-1">
          <button
            @click="changeActiveTab('video')"
            :class="{
              'nav-link': true,
              active: activeTab == 'video',
            }"
          >
            Video
          </button>
        </li>
      </ul>

      <div class="row rassegna-ctn">
        <div
          v-for="review in paginatedRassegna"
          :key="review.id"
          class="col-12 col-sm-6 col-md-4 p-1 rassegna-card-ctn"
        >
          <a
            :href="review.link ? review.link : '/contents/' + review.attachment"
            target="_blank"
          >
            <div class="card border border-primary h-100">
              <div class="card-body d-flex flex-column justify-content-between">
                <div>
                  <p class="card-title">
                    <strong>{{ review.testata }}</strong>
                  </p>
                  <p class="card-subtitle">{{ review.title }}</p>
                </div>
                <div class="d-flex justify-content-end">
                  <p class="card-link">
                    {{
                      review.section == "article"
                        ? "LEGGI"
                        : review.section == "audio"
                        ? "ASCOLTA"
                        : "GUARDA"
                    }}
                  </p>
                </div>
              </div>
            </div>
          </a>
        </div>
        <div
          v-for="(_, index) in placeholderItems"
          :key="'placeholder-' + index"
          class="col-12 col-sm-6 col-md-4 p-1 rassegna-card-ctn"
        ></div>
      </div>
      <div class="row">
        <paginate
          :page-count="Math.ceil(filteredRassegna.length / itemsPerPage)"
          v-model="currentPage"
          :prev-text="''"
          :next-text="''"
          :container-class="'pagination-ctn'"
          :page-class="'pagination-page-item'"
        >
        </paginate>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "rassegnaStampa",
  data() {
    return {
      isMobile: false,
      activeTab: "article",
      currentPage: 1,
    };
  },
  props: ["rassegna"],
  computed: {
    filteredRassegna() {
      return this.rassegna.filter((review) => review.section == this.activeTab);
    },
    paginatedRassegna() {
      return this.filteredRassegna.slice(
        (this.currentPage - 1) * this.itemsPerPage,
        this.currentPage * this.itemsPerPage
      );
    },
    placeholderItems() {
      return this.$screen.sm
        ? new Array(this.itemsPerPage - this.paginatedRassegna.length)
        : 0;
    },
    itemsPerPage() {
      return this.$screen.md ? 9 : 8;
    },
  },
  async mounted() {
    const app = this;
    if (window.innerWidth < 767) {
      app.isMobile = true;
    }
    window.addEventListener("resize", function () {
      if (window.innerWidth > 767) {
        app.isMobile = false;
      } else {
        app.isMobile = true;
      }
    });
  },
  methods: {
    changeActiveTab(tab) {
      if (tab != this.activeTab) {
        this.activeTab = tab;
        this.currentPage = 1;
      }
    },
  },
};
</script>

<style></style>
