<template>
  <div class="row-artist">
    <div class="content-container">
     
      <div class="right-content">
        <div class="col-10 listScroll">
          <ul>
            <li v-for="(member, index) in members" :key="index" class="pe-5 nome">
              <a
                class="contentMarker d-flex mb-4 mt-4"
                :data-marker-content="'img' + member.id"
                
              >
                <p class="text-uppercase">{{ member.name }}</p>
                <p>Title Track</p>

              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="left-content">
            <img
            v-for="(member, index) in members"
            :key="index"
              :id="'img'+ member.id"
              class="imageToShow"
              :src="member.img"
              alt=""
            />
      </div>
    </div>
  </div>
</template>

<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
export default {
  name: "Test",
  data() {
    return {
      members: [
        {
          id: "1",
          name: "Mario Rossi",
          alias: "Lorem Ipsum",
          role: "CO-FOUNDER",
          disorder: "Lore Ipsum",
          img: "https://placekitten.com/300/700?image=1",
        },
        {
          id: "2",
          name: "Giorgia Meloni",
          alias: "Lorem Ipsum",
          role: "CO-FOUNDER",
          disorder: "Lore Ipsum",
          img: "https://placekitten.com/300/700?image=2",
        },
        {
          id: "3",
          name: "Matteo Salvini",
          alias: "Lorem Ipsum",
          role: "CO-FOUNDER",
          disorder: "Lore Ipsum",
          img: "https://placekitten.com/300/700?image=3",
        },
        {
          id: "4",
          name: "Silvio Berluscah",
          alias: "Lorem Ipsum",
          role: "CO-FOUNDER",
          disorder: "Lore Ipsum",
          img: "https://placekitten.com/300/700?image=4",
        },
        {
          id: "5",
          name: "Mario Rossi",
          alias: "Lorem Ipsum",
          role: "CO-FOUNDER",
          disorder: "Lore Ipsum",
          img: "https://placekitten.com/300/700?image=5",
        },
        {
          id: "6",
          name: "Giorgia Meloni",
          alias: "Lorem Ipsum",
          role: "CO-FOUNDER",
          disorder: "Lore Ipsum",
          img: "https://placekitten.com/300/700?image=3",
        },
        {
          id: "7",
          name: "Matteo Salvini",
          alias: "Lorem Ipsum",
          role: "CO-FOUNDER",
          disorder: "Lore Ipsum",
          img: "https://placekitten.com/300/700?image=2",
        },
        {
          id: "8",
          name: "Silvio Berluscah",
          alias: "Lorem Ipsum",
          role: "CO-FOUNDER",
          disorder: "Lore Ipsum",
          img: "https://placekitten.com/300/700?image=1",
        },
        {
          id: "1",
          name: "Mario Rossi",
          alias: "Lorem Ipsum",
          role: "CO-FOUNDER",
          disorder: "Lore Ipsum",
          img: "https://placekitten.com/300/700?image=3",
        },
        {
          id: "2",
          name: "Giorgia Meloni",
          alias: "Lorem Ipsum",
          role: "CO-FOUNDER",
          disorder: "Lore Ipsum",
          img: "https://placekitten.com/300/700?image=2",
        },
        {
          id: "3",
          name: "Matteo Salvini",
          alias: "Lorem Ipsum",
          role: "CO-FOUNDER",
          disorder: "Lore Ipsum",
          img: "https://placekitten.com/300/700?image=3",
        },
        {
          id: "4",
          name: "Silvio Berluscah",
          alias: "Lorem Ipsum",
          role: "CO-FOUNDER",
          disorder: "Lore Ipsum",
          img: "https://placekitten.com/300/700?image=1",
        },
        {
          id: "1",
          name: "Mario Rossi",
          alias: "Lorem Ipsum",
          role: "CO-FOUNDER",
          disorder: "Lore Ipsum",
          img: "https://placekitten.com/300/700?image=4",
        },
        {
          id: "2",
          name: "Giorgia Meloni",
          alias: "Lorem Ipsum",
          role: "CO-FOUNDER",
          disorder: "Lore Ipsum",
          img: "https://placekitten.com/300/700?image=3",
        },
        {
          id: "3",
          name: "Matteo Salvini",
          alias: "Lorem Ipsum",
          role: "CO-FOUNDER",
          disorder: "Lore Ipsum",
          img: "https://placekitten.com/300/700?image=2",
        },
        {
          id: "4",
          name: "Silvio Berluscah",
          alias: "Lorem Ipsum",
          role: "CO-FOUNDER",
          disorder: "Lore Ipsum",
          img: "https://placekitten.com/300/700?image=1",
        },
        {
          id: "1",
          name: "Mario Rossi",
          alias: "Lorem Ipsum",
          role: "CO-FOUNDER",
          disorder: "Lore Ipsum",
          img: "https://placekitten.com/300/700?image=1",
        },
        {
          id: "2",
          name: "Giorgia Meloni",
          alias: "Lorem Ipsum",
          role: "CO-FOUNDER",
          disorder: "Lore Ipsum",
          img: "https://placekitten.com/300/700?image=2",
        },
        {
          id: "3",
          name: "Matteo Salvini",
          alias: "Lorem Ipsum",
          role: "CO-FOUNDER",
          disorder: "Lore Ipsum",
          img: "https://placekitten.com/300/700?image=3",
        },
        {
          id: "4",
          name: "Silvio Berluscah",
          alias: "Lorem Ipsum",
          role: "CO-FOUNDER",
          disorder: "Lore Ipsum",
          img: "https://placekitten.com/300/700?image=4",
        },
        {
          id: "1",
          name: "Mario Rossi",
          alias: "Lorem Ipsum",
          role: "CO-FOUNDER",
          disorder: "Lore Ipsum",
          img: "https://placekitten.com/300/700?image=5",
        },
        {
          id: "2",
          name: "Giorgia Meloni",
          alias: "Lorem Ipsum",
          role: "CO-FOUNDER",
          disorder: "Lore Ipsum",
          img: "https://placekitten.com/300/700?image=3",
        },
        {
          id: "3",
          name: "Matteo Salvini",
          alias: "Lorem Ipsum",
          role: "CO-FOUNDER",
          disorder: "Lore Ipsum",
          img: "https://placekitten.com/300/700?image=2",
        },
        {
          id: "4",
          name: "Silvio Berluscah",
          alias: "Lorem Ipsum",
          role: "CO-FOUNDER",
          disorder: "Lore Ipsum",
          img: "https://placekitten.com/300/700?image=1",
        },
        {
          id: "1",
          name: "Mario Rossi",
          alias: "Lorem Ipsum",
          role: "CO-FOUNDER",
          disorder: "Lore Ipsum",
          img: "https://placekitten.com/300/700?image=3",
        },
        {
          id: "2",
          name: "Giorgia Meloni",
          alias: "Lorem Ipsum",
          role: "CO-FOUNDER",
          disorder: "Lore Ipsum",
          img: "https://placekitten.com/300/700?image=2",
        },
        {
          id: "3",
          name: "Matteo Salvini",
          alias: "Lorem Ipsum",
          role: "CO-FOUNDER",
          disorder: "Lore Ipsum",
          img: "https://placekitten.com/300/700?image=3",
        },
        {
          id: "4",
          name: "Silvio Berluscah",
          alias: "Lorem Ipsum",
          role: "CO-FOUNDER",
          disorder: "Lore Ipsum",
          img: "https://placekitten.com/300/700?image=1",
        },
        {
          id: "1",
          name: "Mario Rossi",
          alias: "Lorem Ipsum",
          role: "CO-FOUNDER",
          disorder: "Lore Ipsum",
          img: "https://placekitten.com/300/700?image=4",
        },
        {
          id: "2",
          name: "Giorgia Meloni",
          alias: "Lorem Ipsum",
          role: "CO-FOUNDER",
          disorder: "Lore Ipsum",
          img: "https://placekitten.com/300/700?image=3",
        },
        {
          id: "3",
          name: "Matteo Salvini",
          alias: "Lorem Ipsum",
          role: "CO-FOUNDER",
          disorder: "Lore Ipsum",
          img: "https://placekitten.com/300/700?image=2",
        },
        {
          id: "4",
          name: "Silvio Berluscah",
          alias: "Lorem Ipsum",
          role: "CO-FOUNDER",
          disorder: "Lore Ipsum",
          img: "https://placekitten.com/300/700?image=1",
        },
        {
          id: "1",
          name: "Mario Rossi",
          alias: "Lorem Ipsum",
          role: "CO-FOUNDER",
          disorder: "Lore Ipsum",
          img: "https://placekitten.com/300/700?image=1",
        },
        {
          id: "2",
          name: "Giorgia Meloni",
          alias: "Lorem Ipsum",
          role: "CO-FOUNDER",
          disorder: "Lore Ipsum",
          img: "https://placekitten.com/300/700?image=2",
        },
        {
          id: "3",
          name: "Matteo Salvini",
          alias: "Lorem Ipsum",
          role: "CO-FOUNDER",
          disorder: "Lore Ipsum",
          img: "https://placekitten.com/300/700?image=3",
        },
        {
          id: "4",
          name: "Silvio Berluscah",
          alias: "Lorem Ipsum",
          role: "CO-FOUNDER",
          disorder: "Lore Ipsum",
          img: "https://placekitten.com/300/700?image=4",
        },
        {
          id: "1",
          name: "Mario Rossi",
          alias: "Lorem Ipsum",
          role: "CO-FOUNDER",
          disorder: "Lore Ipsum",
          img: "https://placekitten.com/300/700?image=5",
        },
        {
          id: "2",
          name: "Giorgia Meloni",
          alias: "Lorem Ipsum",
          role: "CO-FOUNDER",
          disorder: "Lore Ipsum",
          img: "https://placekitten.com/300/700?image=3",
        },
        {
          id: "3",
          name: "Matteo Salvini",
          alias: "Lorem Ipsum",
          role: "CO-FOUNDER",
          disorder: "Lore Ipsum",
          img: "https://placekitten.com/300/700?image=2",
        },
        {
          id: "4",
          name: "Silvio Berluscah",
          alias: "Lorem Ipsum",
          role: "CO-FOUNDER",
          disorder: "Lore Ipsum",
          img: "https://placekitten.com/300/700?image=1",
        },
        {
          id: "1",
          name: "Mario Rossi",
          alias: "Lorem Ipsum",
          role: "CO-FOUNDER",
          disorder: "Lore Ipsum",
          img: "https://placekitten.com/300/700?image=3",
        },
        {
          id: "2",
          name: "Giorgia Meloni",
          alias: "Lorem Ipsum",
          role: "CO-FOUNDER",
          disorder: "Lore Ipsum",
          img: "https://placekitten.com/300/700?image=2",
        },
        {
          id: "3",
          name: "Matteo Salvini",
          alias: "Lorem Ipsum",
          role: "CO-FOUNDER",
          disorder: "Lore Ipsum",
          img: "https://placekitten.com/300/700?image=3",
        },
        {
          id: "4",
          name: "Silvio Berluscah",
          alias: "Lorem Ipsum",
          role: "CO-FOUNDER",
          disorder: "Lore Ipsum",
          img: "https://placekitten.com/300/700?image=1",
        },
        {
          id: "1",
          name: "Mario Rossi",
          alias: "Lorem Ipsum",
          role: "CO-FOUNDER",
          disorder: "Lore Ipsum",
          img: "https://placekitten.com/300/700?image=4",
        },
        {
          id: "2",
          name: "Giorgia Meloni",
          alias: "Lorem Ipsum",
          role: "CO-FOUNDER",
          disorder: "Lore Ipsum",
          img: "https://placekitten.com/300/700?image=3",
        },
        {
          id: "3",
          name: "Matteo Salvini",
          alias: "Lorem Ipsum",
          role: "CO-FOUNDER",
          disorder: "Lore Ipsum",
          img: "https://placekitten.com/300/700?image=2",
        },
        {
          id: "4",
          name: "Silvio Berluscah",
          alias: "Lorem Ipsum",
          role: "CO-FOUNDER",
          disorder: "Lore Ipsum",
          img: "https://placekitten.com/300/700?image=1",
        },
      ],
    };
  },
  mounted() {
    console.clear();

    gsap.defaults({ overwrite: "auto" });
    /* Position container */
    gsap.set(".left-content > *", { xPercent: -50, yPercent: -50 });

    // Set up our scroll trigger
    const ST = ScrollTrigger.create({
      trigger: ".row-artist",
      start: "top top",
      end: "bottom bottom",
      markers:true,
      onUpdate: getCurrentSection,
      pin: ".left-content",
    });
    console.log('COSA E:', getCurrentSection);

    const contentMarkers = gsap.utils.toArray(".contentMarker");

    // Set up our content behaviors
    contentMarkers.forEach((marker) => {
      marker.content = document.querySelector(
        `#${marker.dataset.markerContent}`
        );
        console.log('===>>>',marker.content);

    
        gsap.set(marker.content, { transformOrigin: "right" });

        /* Animation transiction img */
        marker.content.enter = function () {
          gsap.fromTo(
            marker.content,
            { autoAlpha: 0, rotateY: -30 },
            { duration: 0.3, autoAlpha: 1, rotateY: 0 }
          );
        };
      

      marker.content.leave = function () {
        gsap.to(marker.content, { duration: 0.1, autoAlpha: 0 });
      };
    });

    // Handle the updated position
    let lastContent;

    /* let nome = document.getElementsByClassName('nome') */
    
    function getCurrentSection() {
      let newContent;
      const currScroll = scrollY;
      console.log('cazzo',currScroll);

      // Find the current section
      contentMarkers.forEach((marker) => {
        
        if (currScroll > marker.offsetTop) {
          newContent = marker.content;
          document.querySelector('.contentMarker').classList.add('evidenziato')
          /* console.log('ecc', marker.className); */
     
        }
      });

      // If the current section is different than that last, animate in
      if (
        newContent &&
        (lastContent == null || !newContent.isSameNode(lastContent))
      ) {
        // Fade out last section
        if (lastContent) {
          lastContent.leave();
        }

        // Animate in new section
        newContent.enter();

        lastContent = newContent;
      }
    }

    const media = window.matchMedia("screen and (max-width: 100px)");
    ScrollTrigger.addEventListener("refreshInit", checkSTState);
    checkSTState();

    function checkSTState() {
      if (media.matches) {
        ST.disable();
      } else {
        ST.enable();
      }
    }
  },
};
</script>

<style scoped>
img {
/*   height: 100px;
  width: 100px;
  object-fit: cover; */
}
.evidenziato{
    background: red;
}
.content-container {
  display: flex;
  overflow-x:scroll ;
}
.content-container > * {
  width: 50%;
}

.right-content {
  padding-right: 15px;
}

.left-content {
  height: 100vh;
  position: relative;
}

.left-content > * {
  position: absolute;
  left: 50%;
  top: 50%;
  opacity: 0;
  visibility: hidden;
}

@media screen and (max-width: 800px) {
  .left-content {
    position: absolute;
    right: 0px;
    bottom: 0px;
    
  }
  .left-content img{
    width: 100px;
  }
  .right-content {
    padding-left: 15px;
  }
}
</style>
