import Vue from "vue";
import VueRouter from "vue-router";
import Artista from "../views/Artista.vue";
import Artisti from "../views/Artisti.vue";
import Home from "../views/Home.vue";
import News from "../views/News.vue";
import Progetti from "../views/Progetti.vue";
import Progetto from "../views/Progetto.vue";
import Test from "../views/Test.vue";

/* import CoomingSoon from "../views/CoomingSoon.vue"; */

Vue.use(VueRouter);

const routes = [
  /*   {
    path: "/",
    name: "CoomingSoon",
    component: CoomingSoon,
  }, */
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/progetti",
    name: "Progetti",
    component: Progetti,
  },
  {
    path: "/test",
    name: "Test",
    component: Test,
  },
  {
    path: "/progetto/:slug",
    name: "Progetto",
    component: Progetto,
  },
  {
    path: "/artisti",
    name: "Artisti",
    component: Artisti,
  },
  {
    path: "/artista/:slug",
    name: "Artista",
    component: Artista,
  },
  {
    path: "/news",
    name: "News",
    component: News,
  },
];

const router = new VueRouter({
  routes,
  mode: "history",
  // eslint-disable-next-line no-unused-vars
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
});
export default router;
