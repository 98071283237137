<template>
  <div @click="scrollToTop()" class="navigate">
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 1L7.17678 0.823223L7 0.646446L6.82322 0.823223L7 1ZM6.75 13C6.75 13.1381 6.86193 13.25 7 13.25C7.13807 13.25 7.25 13.1381 7.25 13L6.75 13ZM13.1768 6.82322L7.17678 0.823223L6.82322 1.17678L12.8232 7.17678L13.1768 6.82322ZM6.82322 0.823223L0.823223 6.82322L1.17678 7.17678L7.17678 1.17678L6.82322 0.823223ZM6.75 1L6.75 13L7.25 13L7.25 1L6.75 1Z"
        fill="currentColor"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: "scrollUp",
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style></style>
