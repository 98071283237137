<template>
  <div class="progetti">
    <Header />
    <div class="container-fluid space-container">
      <div class="row">
        <div class="col-12 w-100 text-center">
          <h2 class="effect">Progetti</h2>
        </div>
      </div>

      <div class="filters">
        <ul class="d-flex justify-content-between">
          <li @click="resetFilter()">Tutti</li>
          <li @click="selectedFilter = 'current'">In corso</li>
          <li @click="selectedFilter = 'past'">Passati</li>
          <li
            class=""
            style="cursor: pointer"
            @click="showListArtist = !showListArtist"
          >
            artisti
            <i
              :class="!showListArtist ? 'fa-chevron-down' : 'fa-chevron-up'"
              class="fa-solid"
            ></i>
            <ul class="list-filter-artist" v-if="showListArtist">
              <li
                v-for="(artist, index) in uniqueArtists"
                :key="index"
                @click="selectedFilter = artist.artistName"
              >
                {{ artist.artistName }}
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <div
        v-if="this.fetchStatusProjects === 'pending'"
        class="row container-card justify-content-center mt-5 mb-5 pt-5"
      >
        <div class="spinner-border custom-spinner" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      <div
        v-if="
          !isMobile &&
          printedTopProjects.length > 0 &&
          this.fetchStatusProjects === 'success'
        "
        class="row container-cover"
      >
        <div
          v-for="(project, index) in printedTopProjects"
          :key="index"
          class="col-12 col-md-6 col-lg-4 single-project"
        >
          <a :href="'/progetto/' + project.slug">
            <img
              :src="'https://pressismore.it/contents/' + project.cover_image"
              alt="project cover"
            />
            <p>{{ project.artistName }}</p>
          </a>
        </div>
      </div>
      <div class="text-center pt-5" v-if="printedTopProjects.length === 0">
        Nessun Progetto!
      </div>
      <div class="" v-if="isMobile">
        <hooper :settings="hooperSettings">
          <slide
            class=""
            v-for="(project, index) in printedTopProjects"
            :key="index"
          >
            <a :href="'/progetto/' + project.slug">
              <img
                :src="'https://pressismore.it/contents/' + project.cover_image"
                alt="project cover"
              />
            </a>
          </slide>
          <hooper-navigation slot="hooper-addons"></hooper-navigation>
        </hooper>
      </div>

      <h5 v-if="printedAllProjects.length > 0" class="">
        Vedi tutti i progetti
      </h5>

      <div class="row row-artist">
        <div
          v-if="this.fetchStatusProjects === 'pending'"
          class="row container-card justify-content-center mt-5 mb-5 pt-5"
        >
          <div class="spinner-border custom-spinner" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <div
          id="list"
          v-if="printedAllProjects.length > 0"
          class="content-container"
        >
          <div
            v-if="this.fetchStatusProjects === 'success'"
            class="col-12 col-md-12 pe-0 left-content"
            :class="isMobile ? 'ps-0' : null"
          >
            <ul id="listProjects">
              <li
                v-for="(project, index) in printedAllProjects"
                :key="index"
                class="pt-1 pb-1 contentMarker animation-list"
                :class="isMobile ? 'p-0 w-75' : 'pe-4'"
                :data-marker-content="'img' + project.id"
              >
                <a
                  class="text-decoration-none d-flex justify-content-between align-items-center w-100"
                  :href="'/progetto/' + project.slug"
                >
                  <p
                    :class="isMobile ? 'w-100' : 'w-50'"
                    style="
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                    "
                  >
                    {{ project.name }}
                  </p>
                  <p v-if="!isMobile" class="w-50">{{ project.artistName }}</p>
                  <svg
                    v-if="!isMobile"
                    class="rotate"
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7 1L7.17678 0.823223L7 0.646446L6.82322 0.823223L7 1ZM6.75 13C6.75 13.1381 6.86193 13.25 7 13.25C7.13807 13.25 7.25 13.1381 7.25 13L6.75 13ZM13.1768 6.82322L7.17678 0.823223L6.82322 1.17678L12.8232 7.17678L13.1768 6.82322ZM6.82322 0.823223L0.823223 6.82322L1.17678 7.17678L7.17678 1.17678L6.82322 0.823223ZM6.75 1L6.75 13L7.25 13L7.25 1L6.75 1Z"
                      fill="currentColor"
                    />
                  </svg>
                </a>
                <img
                  v-if="!isMobile"
                  class="img-show"
                  :src="
                    'https://pressismore.it/contents/' + project.cover_image
                  "
                  alt="project cover"
                />
              </li>
            </ul>
          </div>
        </div>
        <div v-if="isMobile" class="right-content">
          <img
            v-for="(project, index) in printedAllProjects"
            :key="index"
            :id="'img' + project.id"
            class="imageToShow"
            :src="'https://pressismore.it/contents/' + project.cover_image"
            alt="project cover"
          />
        </div>
      </div>
    </div>

    <div class="b-bottom"></div>

    <Footer />
  </div>
</template>

<script>
import Footer from "@/components/Footer.vue";
import Header from "@/components/Header.vue";
import axios from "axios";
import { Hooper, Navigation as HooperNavigation, Slide } from "hooper";
import "hooper/dist/hooper.css";
import { scrollingGsap } from "../animation-gsap.js";

export default {
  name: "Progetti",
  metaInfo: {
    title: "Progetti",
    meta: [
      {
        property: "og:title",
        content: "Progetti",
        // following template options are identical
        // template: '%s - My page',
        template: (chunk) => `PRESSisMORE - ${chunk}`,
      },
    ],
  },
  data() {
    return {
      isMobile: false,
      showListArtist: false,
      api: process.env.VUE_APP_API_ENDPOINT,
      allProjects: [],
      topProjects: [],
      uniqueArtists: [],
      selectedFilter: "all",
      fetchStatusProjects: "not_fetched",
      hooperSettings: {
        itemsToShow: 1,
        centerMode: false,
        vertical: false,
      },
    };
  },

  components: {
    Header,
    Footer,
    Hooper,
    Slide,
    HooperNavigation,
  },
  watch: {
    selectedFilter() {
      setTimeout(() => {
        this.callGsap();
      }, 200);
    },
  },
  computed: {
    printedAllProjects() {
      const app = this;
      let filteredProjects = [];

      if (app.selectedFilter == "current") {
        filteredProjects = app.allProjects.filter(
          (project) => project.in_promo == "si"
        );
      } else if (app.selectedFilter == "past") {
        filteredProjects = app.allProjects.filter(
          (project) => project.in_promo == "no"
        );
      } else if (app.selectedFilter == "all") {
        filteredProjects = app.allProjects;
      } else {
        filteredProjects = app.allProjects.filter(
          (project) =>
            project.artistName &&
            project.artistName.toLowerCase() == app.selectedFilter.toLowerCase()
        );
      }

      // Ordina la lista per nome
      filteredProjects = filteredProjects.sort((a, b) => {
        if (a.name && b.name) {
          return a.name.localeCompare(b.name);
        } else {
          return 0;
        }
      });
      /* console.log('=>',filteredProjects); */

      return filteredProjects;
    },

    printedTopProjects() {
      const app = this;
      let result;

      if (app.selectedFilter == "current") {
        result = app.allProjects.filter((project) => project.in_promo == "si");
      } else if (app.selectedFilter == "past") {
        result = app.allProjects.filter((project) => project.in_promo == "no");
      } else if (app.selectedFilter == "all") {
        result = app.topProjects;
      } else {
        return app.allProjects.filter(
          (project) =>
            project.artistName &&
            project.artistName.toLowerCase() == app.selectedFilter.toLowerCase()
        );
      }

      return result.slice(0, 9);
    },
  },

  async mounted() {
    const app = this;
    await app.fetchProjects();

    if (window.innerWidth < 767) {
      app.isMobile = true;
      setTimeout(() => {
        this.callGsap();
      }, 200);
    }
    window.addEventListener("resize", function () {
      if (window.innerWidth > 767) {
        app.isMobile = false;
      } else {
        app.isMobile = true;
      }
    });
  },
  methods: {
    async fetchProjects() {
      const app = this;
      try {
        this.fetchStatusProjects = "pending";

        const projects = await axios.get(app.api + "/projects");
        app.allProjects = projects.data;
        /* console.log("allProjects", this.allProjects); */

        app.topProjects = app.allProjects.filter(
          (project) => project.highlights == "si"
        );

        this.uniqueArtists = [
          ...new Set(this.allProjects.map((item) => item.artistName)),
        ]
          .map((artistName) => {
            return { artistName };
          })
          .sort((a, b) => {
            return a.artistName.localeCompare(b.artistName);
          });

        if (this.$route.query["artist"]) {
          app.selectedFilter = decodeURIComponent(this.$route.query["artist"]);
        }
        this.fetchStatusProjects = "success";
      } catch (err) {
        console.log("err:", err);
        this.fetchStatusProjects = "failure";
      }
    },
    callGsap() {
      if (this.isMobile) {
        setTimeout(() => {
          scrollingGsap();
        }, 200);
      }
    },
    resetFilter() {
      const app = this;
      if (app.selectedFilter != "all" || app.artistSelectedCarat != "all") {
        (app.selectedFilter = "all"), (app.artistSelectedCarat = "all");
      } else {
        console.log("questo è else");
        let element = document.getElementById("list");
        element.scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "nearest",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
