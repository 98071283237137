var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"rassegna"},[_c('div',{staticClass:"container-fluid space-container"},[_c('ul',{staticClass:"nav nav-pills nav-fill"},[_c('li',{staticClass:"nav-item p-1"},[_c('button',{class:{
            'nav-link': true,
            active: _vm.activeTab == 'article',
          },attrs:{"aria-current":"page"},on:{"click":function($event){return _vm.changeActiveTab('article')}}},[_vm._v(" Articoli ")])]),_c('li',{staticClass:"nav-item p-1"},[_c('button',{class:{
            'nav-link': true,
            active: _vm.activeTab == 'audio',
          },on:{"click":function($event){return _vm.changeActiveTab('audio')}}},[_vm._v(" Audio ")])]),_c('li',{staticClass:"nav-item p-1"},[_c('button',{class:{
            'nav-link': true,
            active: _vm.activeTab == 'video',
          },on:{"click":function($event){return _vm.changeActiveTab('video')}}},[_vm._v(" Video ")])])]),_c('div',{staticClass:"row rassegna-ctn"},[_vm._l((_vm.paginatedRassegna),function(review){return _c('div',{key:review.id,staticClass:"col-12 col-sm-6 col-md-4 p-1 rassegna-card-ctn"},[_c('a',{attrs:{"href":review.link ? review.link : '/contents/' + review.attachment,"target":"_blank"}},[_c('div',{staticClass:"card border border-primary h-100"},[_c('div',{staticClass:"card-body d-flex flex-column justify-content-between"},[_c('div',[_c('p',{staticClass:"card-title"},[_c('strong',[_vm._v(_vm._s(review.testata))])]),_c('p',{staticClass:"card-subtitle"},[_vm._v(_vm._s(review.title))])]),_c('div',{staticClass:"d-flex justify-content-end"},[_c('p',{staticClass:"card-link"},[_vm._v(" "+_vm._s(review.section == "article" ? "LEGGI" : review.section == "audio" ? "ASCOLTA" : "GUARDA")+" ")])])])])])])}),_vm._l((_vm.placeholderItems),function(_,index){return _c('div',{key:'placeholder-' + index,staticClass:"col-12 col-sm-6 col-md-4 p-1 rassegna-card-ctn"})})],2),_c('div',{staticClass:"row"},[_c('paginate',{attrs:{"page-count":Math.ceil(_vm.filteredRassegna.length / _vm.itemsPerPage),"prev-text":'',"next-text":'',"container-class":'pagination-ctn',"page-class":'pagination-page-item'},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }