<template>
  <div class="artista">
    <Header />
    <div class="container-fluid space-container">
      <div class="row">
        <div v-if="singleProject.name" class="col-12 w-100 text-center">
          <MarqueeText
            class="title-project"
            :repeat="6"
            :duration="singleProject.name.length * 0.5"
            style="z-index: 1"
          >
            <h2 class="">{{ singleProject.name }} &nbsp;</h2>
          </MarqueeText>
        </div>
      </div>
      <div v-if="singleProject.collaboration" class="row">
        <div class="col-12 mt-5 mb-5">
          <p class="medium-text text-center">
            {{ singleProject.collaboration }}
          </p>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div v-if="singleProject.cover_image" class="box-progetto">
            <img
              :src="
                'https://pressismore.it/contents/' + singleProject.cover_image
              "
              alt="project cover"
            />
          </div>
          <ul class="social-artist">
            <li v-if="singleProject.twitter">
              <a :href="singleProject.twitter">
                <i class="fa-brands fa-twitter"></i>
              </a>
            </li>
            <li v-if="singleProject.instagram">
              <a :href="singleProject.instagram">
                <i class="fa-brands fa-instagram"></i>
              </a>
            </li>
            <li v-if="singleProject.facebook">
              <a :href="singleProject.facebook">
                <i class="fa-brands fa-facebook"></i>
              </a>
            </li>
            <li v-if="singleProject.tiktok">
              <a :href="singleProject.tiktok">
                <i class="fa-brands fa-tiktok"></i>
              </a>
            </li>
            <li v-if="singleProject.spotify">
              <a :href="singleProject.spotify">
                <i class="fa-brands fa-spotify"></i>
              </a>
            </li>
            <li v-if="singleProject.multi_link">
              <a :href="singleProject.multi_link" target="_blank">
                <svg
                  id="Livello_1"
                  data-name="Livello 1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 733.86 303.72"
                  style="width: 24px; height: 24px"
                >
                  <path
                    d="M310.05,303.72H151.86C68.12,303.72,0,235.59,0,151.86S68.12,0,151.86,0H310.05V50.62H151.86a101.24,101.24,0,0,0,0,202.48H310.05Z"
                    fill="currentColor"
                  />
                  <path
                    d="M582,303.72H423.81V253.1H582a101.24,101.24,0,0,0,0-202.48H423.81V0H582c83.73,0,151.86,68.12,151.86,151.86S665.73,303.72,582,303.72Z"
                    fill="currentColor"
                  />
                  <polygon
                    points="514.56 126.55 514.56 177.17 392.24 177.17 392.24 237.05 341.62 237.05 341.62 177.17 219.29 177.17 219.29 126.55 341.62 126.55 341.62 66.66 392.24 66.66 392.24 126.55 514.56 126.55"
                    fill="currentColor"
                  />
                </svg>
              </a>
            </li>
          </ul>
        </div>
        <div class="col-12 description-artist">
          <p v-html="singleProject.description" class="info-description"></p>
        </div>
      </div>
    </div>
    <div
      v-if="reviews.length > 0"
      :style="isMobile ? 'position: relative' : 'position: absolute'"
      style="left: 0px"
      class="b-bottom"
    ></div>
    <div class="container-fluid space-container">
      <h6 v-if="reviews.length > 0" class="text-uppercase">Rassegna Stampa</h6>

      <div class="row row-artist row-rassegna">
        <div
          v-if="this.fetchStatusReviews === 'pending'"
          class="row container-card justify-content-center mt-5 mb-5 pt-5"
        >
          <div class="spinner-border custom-spinner" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <div v-if="reviews.length > 0">
          <div
            v-if="this.fetchStatusReviews === 'success'"
            :class="isMobile ? 'p-0' : null"
            class="col-12 pe-0"
          >
            <rassegna-stampa :rassegna="reviews" />
          </div>
        </div>
      </div>

      <div style="position: absolute; left: 0px" class="b-bottom"></div>
      <div
        v-if="this.fetchStatusHighlights === 'pending'"
        class="row container-card justify-content-center mt-5 mb-5 pt-5"
      >
        <div class="spinner-border custom-spinner" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      <div
        v-if="highlights.length > 0 && this.fetchStatusHighlights === 'success'"
        class="row"
      >
        <div class="col-12">
          <h6>In evidenza</h6>
        </div>
        <div
          v-for="(highlight, index) in highlights"
          :key="index"
          class="col-12 col-md-4 box-highlight"
        >
          <a :href="'/progetto/' + highlight.slug">
            <img
              :src="'https://pressismore.it/contents/' + highlight.cover_image"
              alt="project cover"
            />
            <p>{{ highlight.name }}</p>
          </a>
        </div>
      </div>
    </div>

    <div class="b-bottom"></div>
    <Footer />
  </div>
</template>

<script>
import Footer from "@/components/Footer.vue";
import Header from "@/components/Header.vue";
import MarqueeText from "vue-marquee-text-component";

import axios from "axios";
import RassegnaStampa from "../components/rassegnaStampa.vue";

export default {
  name: "Progetto",
  data() {
    return {
      api: process.env.VUE_APP_API_ENDPOINT,
      isMobile: false,
      singleProject: [],
      reviews: [],
      highlights: [],
      fetchStatusReviews: "not_fetched",
      fetchStatusHighlights: "not_fetched",
    };
  },
  components: {
    Header,
    Footer,
    MarqueeText,
    RassegnaStampa,
  },
  watch: {
    $route: async function (newVal) {
      console.log("route", newVal);
      await this.fetchSingleProject();
      await this.fetchReviews();
      await this.fetchHighlights();
    },
  },
  async mounted() {
    const app = this;
    await app.fetchSingleProject();
    await app.fetchReviews();
    await app.fetchHighlights();

    if (window.innerWidth < 767) {
      app.isMobile = true;
    }
    window.addEventListener("resize", function () {
      if (window.innerWidth > 767) {
        app.isMobile = false;
      } else {
        app.isMobile = true;
      }
    });
  },
  methods: {
    async fetchSingleProject() {
      const app = this;
      try {
        const project = await axios.get(
          app.api + "/projectbyslug?slug=" + app.$route.params.slug
        );
        app.singleProject = project.data;
        console.log("singleProject===>", app.singleProject);
      } catch (err) {
        console.log("err:", err);
      }
    },
    async fetchReviews() {
      const app = this;
      try {
        this.fetchStatusReviews = "pending";
        let review;

        review = await axios.get(
          app.api + "/artist-reviews?project=" + app.singleProject.id
        );

        app.reviews = review.data;
        app.reviews.sort((a, b) => new Date(b.date) - new Date(a.date));
        this.fetchStatusReviews = "success";
      } catch (err) {
        console.log("err:", err);
        this.fetchStatusReviews = "failure";
      }
    },
    async fetchHighlights() {
      const app = this;
      try {
        this.fetchStatusHighlights = "pending";

        const highlight = await axios.get(
          app.api +
            "/artist-highlighted-projects?artist=" +
            app.singleProject.artist
        );

        const filteredHighlights = highlight.data.filter(
          (project) =>
            project.name !== app.singleProject.name ||
            project.id !== app.singleProject.id
        );
        const limitedHighlights = filteredHighlights.slice(0, 3);
        app.highlights = limitedHighlights;
        console.log("eccoli", app.highlights);
        this.fetchStatusHighlights = "success";
      } catch (err) {
        console.log("err:", err);
        this.fetchStatusHighlights = "failure";
      }
    },

    /* app.api + "/artist-reviews?artist=" + app.singleProject.artist */

    /* app.api + "/artist-high...-projects?artist=" + app.singleProject.artist */
  },
};
</script>

<style></style>
