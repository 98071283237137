<template>
  <footer>
    <div class="top-footer space-container">
      <div v-if="isMobile">
        <scrollUp />
      </div>
      <div class="container-fluid px-0">
        <div class="row up first-row mb-0 mb-md-5 pb-md-3">
          <div class="col-12 col-md-4">
            <p>Founder - Azzurra Sottosanti</p>
            <a class="medium-text" href="mailto:azzurra@pressismore.it"
              >azzurra@pressismore.it</a
            >
          </div>
          <div class="col-12 col-md-4">
            <p>Coordinatrice progetti - Ilaria Rapa</p>
            <a class="medium-text" href="mailto:ilaria@pressismore.it"
              >ilaria@pressismore.it</a
            >
          </div>
          <div class="col-12 col-md-4">
            <p>Promo radio - Assunta Urbano</p>
            <a class="medium-text" href="mailto:assunta@pressismore.it"
              >assunta@pressismore.it</a
            >
          </div>
        </div>
      </div>
      <div class="row up justify-content-start">
        <div class="col-12 col-md-4">
          <p>Richiedi informazioni</p>
          <a class="medium-text" href="mailto:press@pressismore.it"
            >press@pressismore.it</a
          >
        </div>
        <div class="col-12 col-md-4">
          <p>Seguici su</p>
          <a
            class="medium-text"
            style="margin-right: 10px"
            href="https://facebook.com/pressismore"
            target="_blank"
            >Facebook</a
          >
          <a
            class="medium-text"
            href="https://instagram.com/pressismore_"
            target="_blank"
            >Instagram</a
          >
        </div>
      </div>
    </div>
    <div class="bottom-footer">
      <div>
        <svg
          viewBox="0 0 256 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.8804 1L0 1L0 27L3.84807 27L3.84807 4.34795L11.2827 4.34795C14.7796 4.34795 17.0361 6.12877 17.0361 8.91041C17.0361 11.6137 14.7945 13.3625 11.2827 13.3625L7.70361 13.3625L7.70361 16.7104L11.8804 16.7104C17.2491 16.7104 21 13.5049 21 8.91041C21 4.25178 17.2491 1 11.8804 1Z"
            fill="currentColor"
          />
          <path
            d="M37.2986 14.78C40.3456 13.6118 42.0018 11.457 42.0018 8.62192C42.0018 4.13425 37.9753 1 32.2065 1L22 1L22 27L25.8508 27L25.8508 16.2438L31.6457 16.2438C35.0367 16.2438 36.334 17.5367 37.6836 22.2595L39.0296 27L43 27L41.3101 21.1625C40.6035 18.6658 39.6165 16.2047 37.2986 14.78ZM25.8508 4.34795L32.1691 4.34795C35.7881 4.34795 38.0388 5.9863 38.0388 8.62192C38.0388 11.2575 35.7059 12.8959 31.7953 12.8959L25.8508 12.8959L25.8508 4.34795Z"
            fill="currentColor"
          />
          <path
            d="M49.4934 15.5331L61.6564 15.5331L61.6564 12.1856L49.4934 12.1856L49.4934 4.35105L62.9664 4.35105L62.9664 1L46 1L46 27L63 27V23.6525L49.4934 23.6525L49.4934 15.5331Z"
            fill="currentColor"
          />
          <path
            d="M77.7057 12.4196L76.1922 12.4196C73.1255 12.4196 70.2905 12.0104 70.2905 9.04507C70.2905 6.14081 72.539 4.33865 76.156 4.33865C80.0881 4.33865 82.4307 6.42082 82.7493 10.201L82.7819 10.6929L86.58 10.6929L86.5401 10.1149C86.1346 4.49302 82.1519 1 76.1488 1C69.5084 1 66.4815 5.17152 66.4815 9.04507C66.4815 13.1017 69.8126 15.8301 74.7729 15.8301L76.7643 15.8301C82.0831 15.8301 83.1729 17.4204 83.1729 19.8149C83.1729 22.9382 79.9143 24.6254 76.8729 24.6254C72.7852 24.6254 69.9465 22.1125 69.8126 18.3933V17.8763L66 17.8763V18.4256C66.1159 24.0618 70.5838 28 76.8621 28C83.4481 28 87 23.7818 87 19.8149C87.0108 15.1839 83.5314 12.4196 77.7057 12.4196Z"
            fill="currentColor"
          />
          <path
            d="M101.263 12.4211L99.67 12.4211C96.461 12.4211 93.4872 12.0118 93.4872 9.04614C93.4872 6.14149 95.8427 4.3391 99.6321 4.3391C103.751 4.3391 106.206 6.42154 106.539 10.2023L106.581 10.6977L110.56 10.6977L110.518 10.1197C110.093 4.49348 105.921 1 99.6321 1C92.6755 1 89.5045 5.17207 89.5045 9.04614C89.5045 13.1033 92.9941 15.832 98.1907 15.832L100.277 15.832C105.849 15.832 106.991 17.4226 106.991 19.8174C106.991 22.9411 103.577 24.6286 100.391 24.6286C96.1083 24.6286 93.1345 22.1153 92.9941 18.3956L92.9752 17.875L89 17.875V18.4243C89.1214 24.0613 93.8021 28 100.379 28C107.279 28 111 23.7813 111 19.8138C111.011 15.1858 107.366 12.4211 101.263 12.4211Z"
            fill="currentColor"
          />
          <path
            d="M120.647 7.38862L119.062 26.5073L122.845 26.821L124.43 7.70227L120.647 7.38862Z"
            fill="currentColor"
          />
          <path
            d="M138.706 11.4211L137.185 11.4211C134.118 11.4211 131.283 11.0118 131.283 8.04614C131.283 5.14149 133.532 3.3391 137.149 3.3391C141.081 3.3391 143.423 5.42154 143.742 9.20226L143.782 9.69774L147.576 9.69774L147.537 9.11968C147.135 3.49348 143.152 0 137.149 0C130.505 0 127.482 4.17207 127.482 8.04614C127.482 12.1033 130.813 14.832 135.773 14.832L137.764 14.832C143.083 14.832 144.173 16.4226 144.173 18.8174C144.173 21.9411 140.914 23.6286 137.873 23.6286C133.785 23.6286 130.947 21.1153 130.813 17.3956L130.794 16.875L127 16.875V17.4243C127.116 23.0613 131.584 27 137.862 27C144.448 27 148 22.7813 148 18.8138C148.011 14.1858 144.531 11.4211 138.706 11.4211Z"
            fill="currentColor"
          />
          <path
            d="M198.982 0C191.215 0 186 5.42793 186 13.5018C186 21.5757 191.215 27 198.982 27C206.748 27 212 21.5757 212 13.5018C212 5.42793 206.767 0 198.982 0ZM208.08 13.5018C208.08 19.6172 204.507 23.595 198.978 23.6344H198.904C197.776 23.6606 196.654 23.4682 195.604 23.0685C194.554 22.6688 193.598 22.07 192.79 21.3071C190.912 19.5169 189.92 16.8029 189.92 13.5018C189.92 10.2006 190.919 7.45087 192.809 5.64633C193.605 4.89875 194.548 4.31208 195.581 3.92052C196.614 3.52895 197.717 3.34032 198.826 3.3656H198.974C203.375 3.42647 208.08 6.11895 208.08 13.5018Z"
            fill="currentColor"
          />
          <path
            d="M229.299 14.78C232.342 13.6118 234.002 11.4605 234.002 8.62192C234.002 4.13425 229.972 1 224.207 1L214 1L214 27L217.851 27L217.851 16.2438L223.646 16.2438C227.037 16.2438 228.334 17.5367 229.684 22.2595L231.03 27L235 27L233.31 21.1625C232.604 18.6658 231.617 16.2047 229.299 14.78ZM217.851 4.34795L224.169 4.34795C227.788 4.34795 230.039 5.9863 230.039 8.62192C230.039 11.2575 227.702 12.8959 223.795 12.8959L217.851 12.8959L217.851 4.34795Z"
            fill="currentColor"
          />
          <path
            d="M241.702 23.6525V15.5331L254.577 15.5331V12.1856L241.702 12.1856L241.702 4.35105L255.964 4.35105V1L238 1L238 27L256 27L256 23.6525L241.702 23.6525Z"
            fill="currentColor"
          />
          <path
            d="M184 1L184 27L180.356 26.9679L180.356 10.162C178.279 12.3444 175.36 13.2838 171.937 13.5196L171.473 13.5482C171.16 13.5482 170.844 13.5482 170.531 13.5482L170.067 13.5196C166.64 13.2838 163.721 12.3444 161.648 10.162L161.648 26.9679L158 26.9679L158 1L161.633 1V1.59294C161.626 2.27092 161.695 2.94764 161.839 3.61107C162.176 5.15996 162.963 6.58288 164.107 7.71164C165.167 8.72562 166.466 9.47246 167.891 9.88693C169.909 10.5014 172.072 10.5014 174.09 9.88693C175.515 9.47234 176.813 8.72547 177.871 7.71164C179.019 6.58421 179.809 5.16116 180.15 3.61107C180.294 2.94764 180.363 2.27092 180.356 1.59294V1L184 1Z"
            fill="currentColor"
          />
          <path
            d="M125 0.31068L124.693 4H124.686L121 3.69285L121.307 0L125 0.31068Z"
            fill="currentColor"
          />
        </svg>
      </div>

      <p class="made">
        Developed by
        <a class="medium-text" href="https://yomi.digital/" target="_blank"
          >YOMI</a
        >
      </p>
    </div>
  </footer>
</template>

<script>
import ScrollUp from "./scrollUp.vue";
export default {
  name: "Footer",
  data() {
    return {
      isMobile: false,
    };
  },
  components: {
    ScrollUp,
  },
  mounted() {
    const app = this;
    if (window.innerWidth < 767) {
      app.isMobile = true;
    }
    window.addEventListener("resize", function () {
      if (window.innerWidth > 767) {
        app.isMobile = false;
      } else {
        app.isMobile = true;
      }
    });
  },
};
</script>

<style></style>
