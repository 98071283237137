<template>
  <header class="space-container">
    <div class="logo">
      <a href="/">
        <svg
          viewBox="0 0 925 973"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M805.414 -181.604C732.594 -243.168 631.333 -277.108 520.527 -277.108H0V-145.118H493.041C659.01 -145.118 770.579 -52.5354 770.579 85.2065C770.579 222.948 661.587 310.345 493.041 310.345H258.45V442.336H520.527C631.81 442.336 732.976 409.338 805.414 349.471C883.675 284.701 925 193.345 925 85.4893C925 -22.366 883.198 -115.514 805.414 -181.604Z"
            fill="currentColor"
          />
          <path d="M0 310.948H131.01V441.958H0V310.948Z" fill="currentColor" />
          <path
            d="M904.445 586.223V1429H777.659V914.598C705.508 992.707 603.91 1026.48 485.268 1034.78L469.076 1035.82C458.195 1036.38 447.291 1036.38 436.41 1035.82C431.013 1035.82 425.521 1035.25 420.218 1034.78C301.008 1026.38 199.41 992.707 127.259 914.598V1429H0V586.223H126.785V607.542C126.524 631.818 128.937 656.05 133.981 679.802C145.685 735.257 173.013 786.239 212.76 826.774C249.639 863.024 294.855 889.742 344.469 904.599C414.661 926.672 489.973 926.672 560.165 904.599C609.722 889.738 654.876 863.019 691.684 826.774C731.492 786.269 758.857 735.278 770.558 679.802C775.556 656.043 777.969 631.816 777.754 607.542V586.223H904.445Z"
            fill="currentColor"
          />
        </svg>
      </a>
    </div>
    <nav>
      <ul>
        <li><a href="/artisti">Artisti</a></li>
        <li><a href="/progetti">Progetti</a></li>
        <li><a href="/news">News</a></li>
        <li>
          <a @click="scrollToBottom()" href="javascript:void(0)">Contatti</a>
        </li>
        <li class="me-0">
          <div class="area-theme">
            <div
              :class="isDark ? 'circle-active' : null"
              @click="toggleTheme()"
              class="circle"
            ></div>
            <div
              :class="isLight ? 'circle-active' : null"
              @click="toggleTheme()"
              class="circle"
            ></div>
          </div>
        </li>
      </ul>
    </nav>
  </header>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      userTheme: "dark-theme",
      isDark: true,
      isLight: false,
    };
  },
  mounted() {
    const initUserTheme = this.getTheme() || this.getMediaPreference();
    this.setTheme(initUserTheme);
  },
  methods: {
    toggleTheme() {
      const activeTheme = localStorage.getItem("user-theme");
      if (activeTheme === "dark-theme") {
        this.setTheme("light-theme");
        this.isDark = false;
        this.isLight = true;
      } else {
        this.setTheme("dark-theme");
        this.isDark = true;
        this.isLight = false;
      }
      console.log("click");
    },
    setTheme(theme) {
      localStorage.setItem("user-theme", theme);
      this.userTheme = theme;
      document.documentElement.className = theme;
      this.$emit("change-theme", theme);
    },
    getTheme() {
      return localStorage.getItem("user-theme");
    },
    getMediaPreference() {
      const hasDarkPreference = window.matchMedia(
        "(prefers-color-scheme: dark)"
      ).matches;
      if (hasDarkPreference) {
        return "light-theme";
      } else {
        return "dark-theme";
      }
    },
    scrollToBottom() {
      window.scrollTo(0, document.body.scrollHeight);
    },
  },
};
</script>

<style></style>
