<template>
  <div class="progetti artisti">
    <Header />
    <div class="container-fluid space-container">
      <div class="row">
        <div class="col-12 w-100 text-center">
          <h2 class="effect">Artisti</h2>
        </div>
      </div>

      <div class="filters">
        <ul class="d-flex justify-content-between w-75">
          <li @click="resetFilter()">Tutti</li>
          <li @click="showList = !showList" style="cursor: pointer">
            A - Z
            <i
              :class="!showList ? 'fa-chevron-down' : 'fa-chevron-up'"
              class="fa-solid"
            ></i>
            <ul class="list-alphabet" v-if="showList">
              <li
                v-for="(letter, index) in alphabet"
                :key="index"
                :class="letter.isOn ? 'active' : null"
                @click="artistSelectedCarat = letter.character"
              >
                {{ letter.character }}
              </li>
            </ul>
          </li>
          <li @click="selectedFilter = 'promo'">In Promo</li>
        </ul>
      </div>
      <div
        v-if="this.fetchStatusFavorite === 'pending'"
        class="row container-card justify-content-center mt-5 mb-5 pt-5"
      >
        <div class="spinner-border custom-spinner" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      <div
        v-if="
          !isMobile &&
          printedTopArtist.length > 0 &&
          this.fetchStatusFavorite === 'success'
        "
        class="row container-cover"
      >
        <div
          v-for="(artist, index) in printedTopArtist"
          :key="index"
          class="col-12 col-md-6 col-lg-6 single-project"
        >
          <a :href="'/artista/' + artist.slug">
            <img
              :src="'https://pressismore.it/contents/' + artist.cover_image"
              alt="artist cover"
            />
            <p>{{ artist.name }}</p>
          </a>
        </div>
      </div>
      <div class="text-center pt-5 pb-5" v-if="printedAllArtist.length === 0">
        Nessun Artista!
      </div>
      <div class="" v-if="isMobile">
        <hooper v-if="printedTopArtist.length > 0" :settings="hooperSettings">
          <slide
            class=""
            v-for="(artist, index) in printedTopArtist"
            :key="index"
          >
            <a :href="'/artista/' + artist.slug">
              <img
                :src="'https://pressismore.it/contents/' + artist.cover_image"
                alt="artist cover"
              />
            </a>
          </slide>
          <hooper-navigation slot="hooper-addons"></hooper-navigation>
        </hooper>
      </div>
      <h5 v-if="printedAllArtist.length > 0" class="">
        Vedi tutti gli artisti
      </h5>
      <div class="row row-artist">
        <div
          v-if="this.fetchStatusFavorite === 'pending'"
          class="row container-card justify-content-center mt-5 mb-5 pt-5"
        >
          <div class="spinner-border custom-spinner" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <div
          id="list"
          v-if="printedAllArtist.length > 0"
          class="content-container"
        >
          <div
            v-if="this.fetchStatusFavorite === 'success'"
            class="col-12 col-md-12 pe-0 left-content"
            :class="isMobile ? 'ps-0' : null"
          >
            <ul id="artistListProjects">
              <li
                v-for="(artist, index) in printedAllArtist"
                :key="index"
                class="pt-1 pb-1 contentMarker animation-list"
                :class="isMobile ? 'pe-1 w-75' : 'pe-4'"
                :data-marker-content="'img' + artist.id"
              >
                <a
                  class="text-decoration-none d-flex justify-content-between align-items-center w-100"
                  :href="'/artista/' + artist.slug"
                >
                  <p
                    style="
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                    "
                  >
                    {{ artist.name }}
                  </p>
                </a>
                <img
                  v-if="!isMobile"
                  class="img-show"
                  :src="'https://pressismore.it/contents/' + artist.cover_image"
                  alt="artist cover"
                />
              </li>
            </ul>
          </div>
        </div>
        <div v-if="isMobile" class="right-content">
          <img
            v-for="(artist, index) in printedAllArtist"
            :key="index"
            :id="'img' + artist.id"
            class="imageToShow"
            :src="'https://pressismore.it/contents/' + artist.cover_image"
            alt="artist cover"
          />
        </div>
      </div>
    </div>

    <div class="b-bottom"></div>

    <Footer />
  </div>
</template>

<script>
import Footer from "@/components/Footer.vue";
import Header from "@/components/Header.vue";
import axios from "axios";
import { Hooper, Navigation as HooperNavigation, Slide } from "hooper";
import "hooper/dist/hooper.css";
import { scrollingGsap } from "../animation-gsap.js";

export default {
  name: "Progetti",
  data() {
    return {
      isMobile: false,
      api: process.env.VUE_APP_API_ENDPOINT,
      fetchStatusFavorite: "not_fetched",
      alphabet: [
        "a",
        "b",
        "c",
        "d",
        "e",
        "f",
        "g",
        "h",
        "i",
        "j",
        "k",
        "l",
        "m",
        "n",
        "o",
        "p",
        "q",
        "r",
        "s",
        "t",
        "u",
        "v",
        "w",
        "x",
        "y",
        "z",
      ],
      artistSelectedCarat: "all",
      allArtists: [],
      topArtists: [],

      selectedFilter: "all",

      showList: false,
      hooperSettings: {
        itemsToSlide: 1,
        centerMode: true,
        vertical: false,
      },
    };
  },

  components: {
    Header,
    Footer,
    Hooper,
    Slide,
    HooperNavigation,
  },
  watch: {
    selectedFilter() {
      setTimeout(() => {
        this.callGsap();
      }, 150);
    },
    artistSelectedCarat() {
      setTimeout(() => {
        this.callGsap();
      }, 150);
    },
  },
  computed: {
    printedAllArtist() {
      const app = this;
      if (app.selectedFilter == "promo") {
        return app.allArtists.filter((artist) => artist?.in_promo == "si");
      } else if (app.artistSelectedCarat != "all") {
        if (
          app.alphabet.findIndex(
            (el) => app.artistSelectedCarat == el.character
          ) != -1
        ) {
          return app.allArtists.filter((artist) =>
            artist?.name
              .toLowerCase()
              .startsWith(this.artistSelectedCarat.toLowerCase())
          );
        } else {
          return [];
        }
      } else {
        return app.allArtists;
      }
    },
    printedTopArtist() {
      const app = this;
      if (app.selectedFilter == "promo") {
        return app.topArtists.filter((artist) => artist?.in_promo == "si");
      } else if (app.artistSelectedCarat != "all") {
        if (
          app.alphabet.findIndex(
            (el) => app.artistSelectedCarat == el.character
          ) != -1
        ) {
          return app.topArtists.filter((artist) =>
            artist?.name
              .toLowerCase()
              .startsWith(this.artistSelectedCarat.toLowerCase())
          );
        } else {
          return [];
        }
      } else if (
        app.alphabet.findIndex(
          (el) => app.artistSelectedCarat == el.character
        ) != -1
      ) {
        return app.topArtists.filter((artist) =>
          artist?.name
            .toLowerCase()
            .startsWith(this.artistSelectedCarat.toLowerCase())
        );
      } else {
        return app.topArtists;
      }
    },
  },
  async mounted() {
    const app = this;
    await app.fetchArtist();

    if (window.innerWidth < 767) {
      app.isMobile = true;
      setTimeout(() => {
        this.callGsap();
      }, 150);
    }
    window.addEventListener("resize", function () {
      if (window.innerWidth > 767) {
        app.isMobile = false;
      } else {
        app.isMobile = true;
      }
    });
  },
  methods: {
    async fetchArtist() {
      const app = this;
      try {
        this.fetchStatusFavorite = "pending";
        const artists = await axios.get(app.api + "/artists");
        app.allArtists = artists.data;
        app.topArtists = app.allArtists.filter(
          (artist) => artist.highlights == "si"
        );

        for (let i = 0; i < app.alphabet.length; i++) {
          const element = app.alphabet[i];
          const isOn =
            app.allArtists.findIndex(
              (el) => el.name.toLowerCase()[0] == element
            ) != -1;
          app.alphabet[i] = { character: element, isOn };
        }
        this.fetchStatusFavorite = "success";
      } catch (err) {
        console.log("err:", err);
        this.fetchStatusFavorite = "failure";
      }
    },
    callGsap() {
      if (this.isMobile) {
        scrollingGsap();
      }
    },

    resetFilter() {
      const app = this;
      if (app.selectedFilter != "all" || app.artistSelectedCarat != "all") {
        (app.selectedFilter = "all"), (app.artistSelectedCarat = "all");
      } else {
        console.log("questo è else");
        let element = document.getElementById("list");
        element.scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "nearest",
        });
      }
    },
  },
};
</script>

<style lang="scss">
.active {
  color: #003acc !important;
}
</style>
