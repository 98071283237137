<template>
  <div>
    <div class="container-fluid space-container">
      <div class="row">
        <div class="col-12">
          <p @click="clickBack" class="other mb-5">BACK</p>
        </div>
        <div class="col-12">
          <h4>
            {{ selected.title_news }}
          </h4>
        </div>
        <div class="col-12">
          <img
            :src="'https://pressismore.it/contents/' + selected.cover_image"
            alt=""
          />
        </div>

        <div class="col-12 mb-5 pb-5">
          <p
            v-html="selected.description.slice(0, 800)"
            class="info-description"
            v-if="!showMore"
          ></p>
          <p class="info-description" v-else v-html="selected.description"></p>
          <p
            v-if="selected.description.length > 800"
            class="more-description"
            @click="toggleReadMore"
          >
            {{ showMore ? "Leggi di meno" : "Leggi di più" }}
          </p>
        </div>
      </div>
      <div v-if="
          selected.testo ||
          selected.musiche ||
          selected.voci ||
          selected.cori ||
          selected.chitarre ||
          selected.basso ||
          selected.batteria ||
          selected.registrato ||
          selected.masterizzato ||
          selected.prodotto
        " style="position: absolute; left: 0px" class="b-bottom"></div>
      <div
        v-if="
          selected.testo ||
          selected.musiche ||
          selected.voci ||
          selected.cori ||
          selected.chitarre ||
          selected.basso ||
          selected.batteria ||
          selected.registrato ||
          selected.masterizzato ||
          selected.prodotto
        "
        class="row credits"
      >
        <div class="col-12 col-lg-7">
          <p class="press">Credits</p>
        </div>
        <div :class="isMobile ? 'mt-5' : null" class="col-12 col-lg-5">
          <ul>
            <li v-if="selected.testo">Testo: {{ selected.testo }}</li>
            <li v-if="selected.musiche">
              Musiche e arrangiamenti: {{ selected.musiche }}
            </li>
            <li v-if="selected.voci">Voci: {{ selected.voci }}</li>
            <li v-if="selected.cori">Cori: {{ selected.cori }}</li>
            <li v-if="selected.chitarre">Chitarre: {{ selected.chitarre }}</li>
            <li v-if="selected.basso">Basso: {{ selected.basso }}</li>
            <li v-if="selected.batteria">Batteria: {{ selected.batteria }}</li>
            <li v-if="selected.registrato">
              Registrato e mixado da: {{ selected.registrato }}
            </li>
            <li v-if="selected.masterizzato">
              Masterizzato da: {{ selected.masterizzato }}
            </li>
            <li v-if="selected.prodotto">
              Prodotto da: {{ selected.prodotto }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div
      v-if="allLives.length > 0"
      class="container-fluid mt-5 pb-5 space-container"
    >
      <div class="row pt-4">
        <div class="col-12">
          <p class="press">BRX!T LIVE</p>
        </div>
      </div>
    </div>
    <div class="b-bottom"></div>
    <div
      v-if="this.fetchStatusLives === 'pending'"
      class="row container-card justify-content-center mt-5 mb-5 pt-5"
    >
      <div class="spinner-border custom-spinner" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div v-if="this.fetchStatusLives === 'success'">
      <div
        v-for="(live, index) in allLives"
        :key="index"
        class="list space-container"
      >
        <div class="macro-area">
          <p>{{ live.citta }}</p>
        </div>
        <div class="micro-aree">
          <p v-html="live.location"></p>
          <p v-if="!isMobile" v-html="live.feat"></p>
          <p v-html="live.date"></p>
        </div>
      </div>
    </div>
    <!-- <div style="border-bottom: none" class="list space-container">
      <div class="macro-area">
        <p style="text-transform: capitalize">Torino</p>
      </div>
      <div class="micro-aree ">
        <p>Spazio 211</p>
        <p>w/ Elephant Brain</p>
        <p>04.11</p>
      </div>
    </div> -->
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "singleNews",
  data() {
    return {
      isMobile: false,
      api: process.env.VUE_APP_API_ENDPOINT,
      allLives: [],
      showMore: false,
      fetchStatusLives: "not_fetched",
    };
  },
  props: ["openArtist", "selected"],
  async mounted() {
    const app = this;
    console.log("entrato");
    await this.liveArtist();
    if (window.innerWidth < 767) {
      app.isMobile = true;
    }
    window.addEventListener("resize", function () {
      if (window.innerWidth > 767) {
        app.isMobile = false;
      } else {
        app.isMobile = true;
      }
    });
  },
  methods: {
    clickBack() {
      this.$emit("onBackClick");
    },
    async liveArtist() {
      const app = this;
      try {
        this.fetchStatusLives = "pending";

        const lives = await axios.get(
          app.api + "/livebyartist?artist=" + this.selected.artist
        );
        app.allLives = lives.data;
        console.log("allLives", this.allLives);
        this.fetchStatusLives = "success";
      } catch (err) {
        console.log("err:", err);
        this.fetchStatusLives = "failure";
      }
    },
    toggleReadMore() {
      this.showMore = !this.showMore;
    },
  },
};
</script>

<style></style>
