import Vue from "vue";
import App from "./App.vue";
import router from "./router";

import VueMarquee from "vue-marquee-component";
import VueMeta from "vue-meta";
import VueScreen from "vue-screen";
import Paginate from "vuejs-paginate";
Vue.use(VueMarquee);
Vue.use(VueScreen, "bootstrap");
Vue.use(VueMeta);
Vue.component("paginate", Paginate);

import "@/assets/fonts/font.css";
import "@/themes/color.scss";
// organize-imports-disable-next-line
import "@/themes/style.scss";
// organize-imports-disable-next-line
import "@/themes/resp.scss";
import "./registerServiceWorker";

Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
