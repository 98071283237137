<template>
  <div class="news">
    <Header />
    <div class="container-fluid space-container">
      <div class="row">
        <div class="col-12 w-100 text-center">
          <h2 class="effect">News</h2>
        </div>
      </div>
      <div v-if="!openArtist">
        <div class="filters">
          <ul class="d-flex justify-content-between align-items-center">
            <li @click="resetFilter()">Tutti</li>
            <li>
              <input
                type="text"
                placeholder="Cerca per titolo..."
                v-model="searchTitle"
              />
            </li>
            <li>
              <input
                type="text"
                placeholder="Cerca per artista..."
                v-model="searchArtist"
              />
            </li>
            <li>
              <input
                type="number"
                placeholder="Cerca per anno..."
                v-model="searchYear"
              />
            </li>
          </ul>
        </div>
        <div
          v-if="this.fetchStatusNews === 'pending'"
          class="row container-card justify-content-center mt-5 mb-5 pt-5"
        >
          <div class="spinner-border custom-spinner" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <div
          v-if="!isMobile && this.fetchStatusNews === 'success'"
          class="row container-cover"
        >
          <div
            v-for="(item, index) in filterSearch"
            :key="index"
            class="col-12 col-md-6 col-lg-4 single-new"
          >
            <img
              :src="'https://pressismore.it/contents/' + item.cover_image"
              alt="news cover"
            />
            <a
              @click="singleArtist(item)"
              style="text-decoration: none"
              href="javascript:void(0)"
            >
              <div class="info-project">
                <h4>
                  {{ item.title_preview }}
                  <svg
                    class="arrow"
                    viewBox="0 0 30 35"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M28.9959 0.9095C28.9458 0.35949 28.4594 -0.0458109 27.9094 0.0042359L18.9464 0.819824C18.3964 0.869874 17.9911 1.35632 18.0411 1.90633C18.0912 2.45635 18.5776 2.86165 19.1277 2.8116L27.0947 2.08663L27.8197 10.0537C27.8698 10.6037 28.3562 11.009 28.9062 10.959C29.4562 10.9089 29.8615 10.4225 29.8115 9.87247L28.9959 0.9095ZM1.76828 34.0459L28.7683 1.64024L27.2317 0.360003L0.231724 32.7656L1.76828 34.0459Z"
                      fill="currentColor"
                    />
                  </svg>
                </h4>
              </div>
            </a>
          </div>
          <div v-if="filterSearch.length === 0">Nessuna News!</div>
        </div>
        <div v-if="isMobile">
          <hooper v-if="filterSearch.length > 0" :settings="hooperSettings">
            <slide class="" v-for="(item, index) in filterSearch" :key="index">
              <img
                :src="'https://pressismore.it/contents/' + item.cover_image"
                alt="news cover"
              />
              <a
                @click="singleArtist(item)"
                style="text-decoration: none"
                href="javascript:void(0)"
              >
                <div class="info-project">
                  <h4 :class="{ 'text-short': isMobile }">
                    {{ item.title_preview }}
                    <svg
                      class="arrow"
                      viewBox="0 0 30 35"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M28.9959 0.9095C28.9458 0.35949 28.4594 -0.0458109 27.9094 0.0042359L18.9464 0.819824C18.3964 0.869874 17.9911 1.35632 18.0411 1.90633C18.0912 2.45635 18.5776 2.86165 19.1277 2.8116L27.0947 2.08663L27.8197 10.0537C27.8698 10.6037 28.3562 11.009 28.9062 10.959C29.4562 10.9089 29.8615 10.4225 29.8115 9.87247L28.9959 0.9095ZM1.76828 34.0459L28.7683 1.64024L27.2317 0.360003L0.231724 32.7656L1.76828 34.0459Z"
                        fill="currentColor"
                      />
                    </svg>
                  </h4>
                </div>
              </a>
            </slide>
            <hooper-navigation slot="hooper-addons"></hooper-navigation>
          </hooper>
          <div class="pt-5 pb-5 text-center" v-if="filterSearch.length == 0">
            Nessuna News!
          </div>
        </div>
        <!-- <div class="mb-5">
          <p class="other text-start mt-5 pt-5">Vedi tutti</p>
        </div> -->
      </div>
    </div>
    <div class="info-news" v-if="openArtist">
      <singleNews
        @onBackClick="
          () => {
            openArtist = !openArtist;
          }
        "
        :openArtist="openArtist"
        :selected="selected"
      />
    </div>

    <div v-if="!openArtist" class="b-bottom"></div>

    <Footer />
  </div>
</template>

<script>
import Footer from "@/components/Footer.vue";
import Header from "@/components/Header.vue";
import singleNews from "@/components/singleNews.vue";
import axios from "axios";

import { Hooper, Navigation as HooperNavigation, Slide } from "hooper";
import "hooper/dist/hooper.css";
export default {
  name: "News",
  data() {
    return {
      api: process.env.VUE_APP_API_ENDPOINT,
      openArtist: false,
      isMobile: false,
      allNews: [],
      searchArtist: "",
      searchTitle: "",
      searchYear: "",
      selected: {},
      fetchStatusNews: "not_fetched",
      hooperSettings: {
        itemsToShow: 2,
        itemsToSlide: 1,
        centerMode: false,
        vertical: true,
        touchDrag: false,
        mouseDrag: false,
      },
    };
  },
  components: {
    Header,
    singleNews,
    Footer,
    Hooper,
    Slide,
    HooperNavigation,
  },
  async beforeMount() {
    await this.fetchNews();
  },
  async mounted() {
    const app = this;

    if (window.innerWidth < 767) {
      app.isMobile = true;
    }
    window.addEventListener("resize", function () {
      if (window.innerWidth > 767) {
        app.isMobile = false;
      } else {
        app.isMobile = true;
      }
    });
  },
  computed: {
    filterSearch() {
      return this.allNews.filter((item) => {
        console.log(item);
        let matchesArtist = true;
        if (item.artist_name != null || item.artist_name != undefined) {
          matchesArtist = item.artist_name
            ?.toLowerCase()
            ?.startsWith(this.searchArtist?.toLowerCase());
        } else if (this.searchArtist != "") {
          if (item.artist_name == null || item.artist_name !== undefined) {
            matchesArtist = false;
          }
        }

        const matchesTitle = item.title_preview
          ?.toLowerCase()
          ?.includes(this.searchTitle.toLowerCase());
        const matchesDate = item.year?.startsWith(this.searchYear);
        return matchesArtist && matchesTitle && matchesDate;
      });
    },
  },
  methods: {
    singleArtist(item) {
      const app = this;
      app.openArtist = !app.openArtist;
      app.selected = item;
      if (!app.isMobile) {
        window.scrollTo(0, 650);
      } else {
        window.scrollTo(0, 250);
      }
    },
    async fetchNews() {
      const app = this;
      try {
        this.fetchStatusNews = "pending";
        const news = await axios.get(app.api + "/newsbytime");
        app.allNews = news.data;
        console.log(app.allNews);
        if (this.$route.query["id"]) {
          const correspondingItem = app.allNews.find(
            (el) => el.id.toString() == this.$route.query["id"].toString()
          );
          if (correspondingItem != null && correspondingItem != undefined) {
            app.singleArtist(correspondingItem);
          }
        }
        if (this.$route.query["artist"]) {
          app.searchArtist = decodeURIComponent(this.$route.query["artist"]);
        }
        this.fetchStatusNews = "success";
      } catch (err) {
        console.log("err:", err);
        this.fetchStatusNews = "failure";
      }
    },
    resetFilter() {
      const app = this;
      app.searchArtist = "";
      app.searchTitle = "";
      app.searchYear = "";
    },
  },
};
</script>

<style>
.text-short {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}
</style>
