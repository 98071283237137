import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import { ScrollToPlugin } from "gsap/ScrollToPlugin";
gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);




export function scrollingGsap() {
    gsap.defaults({ overwrite: "auto" });
    /* Position container */
    gsap.set(".right-content > *", { xPercent: 100, yPercent: 0 });

    // Set up our scroll trigger
    const ST = ScrollTrigger.create({
      trigger: "",
      start: "top top",
      scroller: ".content-container",

      onUpdate: getCurrentSection,
    });

    const contentMarkers = gsap.utils.toArray(".contentMarker");
    // Set up our content behaviors
    contentMarkers.forEach((marker) => {
      marker.content = document.querySelector(
        `#${marker.dataset.markerContent}`
        );
        
      gsap.set(marker.content, { transformOrigin: "right" });

      /* Animation transiction img */
      marker.content.enter = function () {
        gsap.fromTo(
          marker.content,
          { autoAlpha: 0, rotateY: -30 },
          { duration: 0.2, autoAlpha: 1, rotateY: 0 }
        );
      };

      marker.content.leave = function () {
        gsap.to(marker.content, { duration: 0.1, autoAlpha: 0 });
      };
    });

    // Handle the updated position
    let lastContent;


    function getCurrentSection() {
      let newContent;
    
      const contentContainer = document.getElementsByClassName("content-container");
      if (
        contentContainer != null &&
        contentContainer != undefined &&
        contentContainer.length > 0
      ) {
        const container = contentContainer[0];
        const currScroll = container.scrollTop + 12;
    
        // Find the current section
        contentMarkers.forEach((marker) => {
          if (currScroll > marker.offsetTop) {
            newContent = marker.content;
            marker.classList.toggle("focus-item", true);
          } else {
            marker.classList.toggle("focus-item", false);
          }
        });
    
        // If the current section is different than the last, animate in
        if (
          newContent &&
          (lastContent == null || !newContent.isSameNode(lastContent))
        ) {
          // Fade out last section
          if (lastContent) {
            lastContent.leave();
          }
    
          // Animate in new section
          newContent.enter();
    
          lastContent = newContent;
        }
    
      
      }
    }
    

    const media = window.matchMedia("screen and (max-width: 100px)");
    ScrollTrigger.addEventListener("refreshInit", checkSTState);
    checkSTState();
    function checkSTState() {
      if (media.matches) {
        ST.disable();
      } else {
        ST.enable();
      }
    }
  }