<template>
  <div class="artista">
    <Header />
    <div class="container-fluid space-container">
      <div class="row">
        <div v-if="singleArtist.name" class="col-12 w-100 text-center">
          <MarqueeText
            class="title-project"
            :repeat="6"
            :duration="6"
            style="z-index: 1"
          >
            <h2 class="">{{ singleArtist.name }} &nbsp;</h2>
          </MarqueeText>
        </div>
      </div>
      <div class="row mb-5">
        <div class="col-12">
          <a class="other" href="/artisti">Back</a>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div v-if="singleArtist.cover_image" class="box-cover">
            <img
              class="img-background"
              :src="
                'https://pressismore.it/contents/' + singleArtist.cover_image
              "
              alt="artist cover"
            />
            <img
              class="img-rotate"
              :src="
                'https://pressismore.it/contents/' + singleArtist.cover_image
              "
              alt="artist cover"
            />
          </div>
          <ul class="social-artist">
            <li v-if="singleArtist.twitter">
              <a :href="singleArtist.twitter" target="_blank">
                <i class="fa-brands fa-twitter"></i>
              </a>
            </li>
            <li v-if="singleArtist.instagram">
              <a :href="singleArtist.instagram" target="_blank">
                <i class="fa-brands fa-instagram"></i>
              </a>
            </li>
            <li v-if="singleArtist.facebook">
              <a :href="singleArtist.facebook" target="_blank">
                <i class="fa-brands fa-facebook"></i>
              </a>
            </li>
            <li v-if="singleArtist.tiktok">
              <a :href="singleArtist.tiktok" target="_blank">
                <i class="fa-brands fa-tiktok"></i>
              </a>
            </li>
            <li v-if="singleArtist.spotify">
              <a :href="singleArtist.spotify" target="_blank">
                <i class="fa-brands fa-spotify"></i>
              </a>
            </li>
            <li v-if="singleArtist.youtube">
              <a :href="singleArtist.youtube" target="_blank">
                <i class="fa-brands fa-youtube"></i>
              </a>
            </li>
            <li v-if="singleArtist.site">
              <a :href="singleArtist.site" target="_blank">
                <i class="fa-solid fa-globe"></i>
              </a>
            </li>
            <li v-if="singleArtist.bandcamp">
              <a :href="singleArtist.bandcamp" target="_blank">
                <i class="fa-brands fa-bandcamp"></i>
              </a>
            </li>
          </ul>
        </div>
        <div class="col-12 description-artist">
          <p v-html="singleArtist.description" class="info-description"></p>
          <div class="d-flex flex-row">
            <a :href="'/news?artist=' + encodeURIComponent(singleArtist.name)"
              >Leggi le news</a
            >
            <a
              class="ms-2"
              :href="
                '/progetti?artist=' + encodeURIComponent(singleArtist.name)
              "
              >Vedi Progetti</a
            >
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="reviews.length > 0"
      :style="isMobile ? 'position: relative' : 'position: absolute'"
      style="left: 0px"
      class="b-bottom"
    ></div>
    <div class="container-fluid space-container">
      <h6 v-if="reviews.length > 0" class="text-uppercase">Rassegna Stampa</h6>

      <div class="row row-artist row-rassegna">
        <div
          v-if="this.fetchStatusReviews === 'pending'"
          class="row container-card justify-content-center mt-5 mb-5 pt-5"
        >
          <div class="spinner-border custom-spinner" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <div v-if="reviews.length > 0">
          <div
            v-if="this.fetchStatusReviews === 'success'"
            :class="isMobile ? 'p-0' : null"
            class="col-12 pe-0"
          >
            <rassegna-stampa :rassegna="reviews" />
          </div>
        </div>
      </div>

      <div style="position: absolute; left: 0px" class="b-bottom"></div>
      <div
        v-if="this.fetchStatusHighlights === 'pending'"
        class="row container-card justify-content-center mt-5 mb-5 pt-5"
      >
        <div class="spinner-border custom-spinner" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      <div
        v-if="highlights.length > 0 && this.fetchStatusHighlights === 'success'"
        class="row"
      >
        <div class="col-12">
          <h6>In evidenza</h6>
        </div>
        <div
          v-for="(highlight, index) in highlights"
          :key="index"
          class="col-12 col-md-4 box-highlight"
        >
          <a :href="'/progetto/' + highlight.slug">
            <img
              :src="'https://pressismore.it/contents/' + highlight.cover_image"
              alt="project cover"
            />
            <p>{{ highlight.name }}</p>
          </a>
        </div>
      </div>
    </div>

    <div class="b-bottom"></div>

    <Footer />
  </div>
</template>

<script>
import Footer from "@/components/Footer.vue";
import Header from "@/components/Header.vue";
import axios from "axios";
import MarqueeText from "vue-marquee-text-component";
import { scrollingGsap } from "../animation-gsap.js";
import RassegnaStampa from "../components/rassegnaStampa.vue";
export default {
  name: "Artista",
  data() {
    return {
      isMobile: false,
      singleArtist: [],
      reviews: [],
      highlights: [],
      fetchStatusReviews: "not_fetched",
      fetchStatusHighlights: "not_fetched",
      api: process.env.VUE_APP_API_ENDPOINT,
    };
  },
  components: {
    Header,
    Footer,
    MarqueeText,
    RassegnaStampa,
  },

  async mounted() {
    const app = this;
    await app.fetchSingleArtist();
    await app.fetchReviews();
    await app.fetchHighlights();
    console.log("===>", app.$route.params.slug);
    if (window.innerWidth < 767) {
      app.isMobile = true;
      setTimeout(() => {
        this.callGsap();
      }, 150);
    }
    window.addEventListener("resize", function () {
      if (window.innerWidth > 767) {
        app.isMobile = false;
      } else {
        app.isMobile = true;
      }
    });
  },
  methods: {
    callGsap() {
      scrollingGsap();
    },
    async fetchSingleArtist() {
      const app = this;
      try {
        const artist = await axios.get(
          app.api + "/artistbyslug?slug=" + app.$route.params.slug
        );
        app.singleArtist = artist.data;
        console.log("===>", artist.data);
      } catch (err) {
        console.log("err:", err);
      }
    },
    async fetchReviews() {
      const app = this;
      try {
        this.fetchStatusReviews = "pending";
        const review = await axios.get(
          app.api + "/artist-reviews?artist=" + app.singleArtist.id
        );
        app.reviews = review.data;
        app.reviews.sort((a, b) => new Date(b.date) - new Date(a.date));
        console.log("Reviews===>", app.reviews);
        this.fetchStatusReviews = "success";
      } catch (err) {
        console.log("err:", err);
        this.fetchStatusReviews = "failure";
      }
    },
    async fetchHighlights() {
      const app = this;
      try {
        this.fetchStatusHighlights = "pending";
        const highlight = await axios.get(
          app.api + "/artist-highlighted-projects?artist=" + app.singleArtist.id
        );
        const limitedHighlights = highlight.data.slice(0, 3);
        app.highlights = limitedHighlights;
        this.fetchStatusHighlights = "success";
      } catch (err) {
        console.log("err:", err);
        this.fetchStatusHighlights = "failure";
      }
    },
  },
};
</script>

<style></style>
